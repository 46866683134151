._4gjc {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgb(42, 60, 144);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
}

._4gjc .navigation {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.80);
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 25px;
    font-size: 13px;
}

._4gjc .navigation .routes {
    display: flex;
    gap: 10px;
}

._4gjc .navigation .routes > div {
    width: 8vw;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.80);
    cursor: pointer;
}

._4gjc .navigation .routes > div:last-of-type {
    border-color: transparent;
}

._4gjc .navigation .logo {
    width: 11vw;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: white;
}

._4gjc .navigation .logo img {
    width: 9vw;
}

._4gjc .navigation .routes {
    display: flex;
}

._4gjc .report {
    color: white;
    background-color: rgba(255, 255, 255, 0.45);
    display: flex;
    align-items: center;
    height: 54px;
    border-radius: 30px;
    font-size: 14px;
}

._4gjc .report .report-id {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;
    background-color: rgba(0,173,239);
    width: 110px;
    height: 54px;
    border-radius: 30px;
}

._4gjc .report .report-id strong {
    font-size: 13px;
}

._4gjc .report .report-date {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
    padding-right: 14px;
}

@media (max-width:560px) {
    ._4gjc {
        position: relative;
    }

    ._4gjc .report {
        display: none;
    }

    ._4gjc .navigation .routes {
        display: none;
    }

    ._4gjc .navigation .logo {
        width: 30vw;
    }

    ._4gjc .navigation .logo img {
        width: 25vw;
    }
}
