.first_section_last_top_container{
    /* margin-bottom: 50px; */
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.first_section_last_top_container .first_section_last_sub{
width:80%;
height: fit-content;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
margin:100px;
border: 6px solid #2a3c90;
border-radius: 30px;
/* gap: 30px; */
gap:10px;

}
.first_section_last_top_container .first_section_last_sub .data{
    width: 200px;
    /* height: 280px; */
    /* height: 350px; */
    height:fit-content;
    /* margin-top: 100px; */
    margin-top: 80px;
    margin-bottom:40px;
    /* background-color: red; */

   
    
}
/* if border allow on tablets also then max-width change only in 760px  */
@media(max-width:1024px){
    .first_section_last_top_container .first_section_last_sub .data{
        margin-top:0;
    }
    .first_section_last_top_container .first_section_last_sub{
        border: none;
    }
 
}

.first_section_last_top_container .first_section_last_sub .one{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
.first_section_last_top_container .first_section_last_sub .one .image-container{
    background-color: #2bace2;
    width: 130px;
    height:130px;
    text-align: center;
    border: 5px solid black;
    border-radius: 50%;
    cursor: pointer;
    transform-origin: center;
    transition: transform 0.5s ease-in-out;
}

.first_section_last_top_container .first_section_last_sub .one .minus-icon div {
  scale: 2.5;
  transform: translateY(-2.7px);
}

.first_section_last_top_container .first_section_last_sub .one h1{
    font-size: 20px;
    text-align: center;
}
.first_section_last_top_container .first_section_last_sub .one p{
    color: rgb(46, 42, 42);
    text-align: center;
}
.first_section_last_top_container .first_section_last_sub .one .img1{
    height: 80px;
    width: 80px;
    margin-top: 22px;
}

  
.first_section_last_top_container .first_section_last_sub .data .img-con-one:hover{
    transform: scale(1.1);
  }
  .first_section_last_top_container .first_section_last_sub .data .img-con-two:hover{
    transform: scale(1.1);
  }
  .first_section_last_top_container .first_section_last_sub .data .img-con-three:hover{
    transform: scale(1.1);
  }
  .first_section_last_top_container .first_section_last_sub .data .img-con-four:hover{
    transform: scale(1.1);
  }
  .first_section_last_top_container .first_section_last_sub .data .img-con-five:hover{
    transform: scale(1.1);
  }



  .first_section_last_top_container .first_section_last_sub .motospy-text{
    position: absolute;
   margin-bottom: 430px;
   background-color: white;
   width: 450px;
   height:120px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
    line-height: 0;
  }
  .first_section_last_top_container .first_section_last_sub .motospy-text::before{
    content: "";
    position:absolute;
    background-color: #2a3c90;
    width: 25px;
    height:25px;
    bottom: 50px;

    border-radius: 50%;
    right:430px;
  }
  .first_section_last_top_container .first_section_last_sub .motospy-text::after{
    content: "";
    position:absolute;
    background-color: #2a3c90;
    width: 25px;
    height:25px;
    bottom: 50px;
    border-radius: 50%;
    left:430px;
  }

  .first_section_last_top_container .first_section_last_sub .motospy-text h1{
    color: #2bace2;
    font-weight: 900;
    font-size: 60px;
    text-transform: uppercase;
  }
  .first_section_last_top_container .first_section_last_sub .motospy-text p{
    color: #2a3c90;
    font-weight: normal;
    font-size: 25px;
    text-transform: capitalize;
    margin-top: -10px;

  }
 @media(max-width:1024px){
    .first_section_last_top_container .first_section_last_sub .motospy-text::before{
        display: none;
    }
    .first_section_last_top_container .first_section_last_sub .motospy-text::after{
        display: none;
    }
 }

 @media(min-width:760px) and (max-width:1024px){
    .first_section_last_top_container .first_section_last_sub .motospy-text{
        /* width: 100%;
        margin-top: -48rem;
        height: fit-content; */
        display: none;
       }
       /* .first_section_last_top_container .first_section_last_sub .motospy-text{
      background-color: red;
        } */
       
 }
 @media(max-width:760px){
  .first_section_last_top_container .first_section_last_sub .motospy-text{
   width: 100%;
   /* margin-top: -89rem; */
   margin-top: -76rem;
   display: none;
   height: fit-content;
  }


 }



 @media(min-width:1024px) and (max-width:1280px)
 {
  .first_section_last_top_container .first_section_last_sub .motospy-text{
  margin-top: 5px;
  }


.first_section_last_top_container .first_section_last_sub{

  width: 100%;
}
/* .first_section_last_top_container .first_section_last_sub .motopsy-text2{
  background-color: red;
} */

 }

._0ybt .icon {
	display: inline-block;
	position:relative;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 90px;
	top:-17px;
	font-size: 30px;
	color: white;
	background-color: #06FF00;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

._0ybt .blacklist-icon {
	background-color: red;
	font-size: 25px;
}
