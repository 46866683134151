._9gba {
    font-family: 'Roboto', sans-serif;
}

._2stg {
    margin-top: 88px;
}

._2stg .heading {
    display: flex;
    height: 40px;
    background-color: rgb(236, 240, 244);
    border-radius: 20px;
    margin: 0px 20px 0px 20px;
}

._2stg .heading .registration-info {
    font-size: 12px;
    background-color: rgb(133, 211, 242);
    display: flex;
    margin: 7px 7px 7px auto;
    border-radius: 18px;
}

._2stg .heading .registration-info h4 {
    all: unset;
    display: flex;
    align-items: center;
    color: white;
    background-color: rgba(0,173,239);
    border-radius: 18px;
    padding: 0px 12px;
}

._2stg .heading .registration-info span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
}

._2stg .hero-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

._2stg .hero-image > img {
    width: 25vw;
}

._2stg .information-box {
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
}

._2stg .information-box > div {
    flex: 1;
    min-height: 100px;
}

._2stg .information-box .card h3 {
    all: unset;
    display: block;
    text-align: center;
    margin: 40px 30px 10px 30px;
    padding: 10px;
    font-weight: 600;
    border-radius: 20px;
    color: white;
    background-color: rgb(42, 60, 144);
}

._2stg .information-box .card > div {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-left: 38px;
}

._2stg .information-box .card .card-item {
    display: flex;
    gap: 12px;
    font-size: 13px;
}

._2stg .information-box .card .card-item span {
    width: 120px;
    padding: 10px;
    display: flex;
    align-items: center;
}

._2stg .information-box .card .card-item span:first-of-type {
    background-color: rgba(164,226,249);
}

._2stg .information-box .card .card-item span:nth-of-type(2) {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
} 

._2stg .information-box .card .card-item .content-overflow {
    display: inline-block;
    word-wrap: break-word;
}

@media (max-width:560px) {
    ._2stg {
        margin-top: 20px;
    }

    ._2stg .information-box {
        flex-direction: column;
    }

    ._2stg .information-box .card h3 {
        width: 292px;
    }
}
