._3kwf {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.399);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

._3kwf ._3rbo {
    position: relative;
    width: 70vw;
    min-height: 100px;
    background-color: white;
}

._3kwf ._3rbo > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    fill: grey;
    width: 20px;
    height: 20px;
}

._3rbo .vehicle-filter {
    height: 150px;
    background-color: rgb(229, 248, 254);
    padding-top: 30px;
}

._3rbo .vehicle-filter .container {
    display: flex;
    align-items: center;
    border: 2px solid rgb(42, 60, 144);
    margin: 20px 5vw 0px 5vw;
    height: 60px;
    border-radius: 40px;
}

._3rbo .vehicle-filter .container > div:not(:last-of-type) {
    position: relative;
    flex: 1;
    color: grey;
    border-right: 1px solid rgb(222, 213, 213);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

._3rbo .vehicle-filter .container > div svg {
    height: 23px;
    width: 23px;
    fill: rgb(62, 187, 233);
}

._3rbo .vehicle-filter .container .mileage-input {
    border-color: transparent !important;
}

._3rbo .vehicle-filter .container .search-button {
    width: 100px;
    background-color: rgb(42, 60, 144);
    height: 100%;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

._3rbo .vehicle-filter .container .search-button svg {
    fill: white;
    width: 40px;
    height: 40px;
}

