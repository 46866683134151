._3foc {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
}

._3foc .dropdown {
    position: absolute;
    top: 60px;
    left: -20px;
    width: 180px;
    height: 140px;
    border: 1px solid #C7BCA1;
    background-color: white;
    border-radius: 8px;
    z-index: 2;
    overflow-y: scroll;
}

._3foc .dropdown > div {
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
}

._3foc .dropdown > div.dark {
    background-color: #f9f4e6;
}
