.first-section-top-div {
    width:100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(185, 242, 244);

}
@media(max-width:1024px){
.first-section-top-div {
gap: 20px;
width:100%;
}
}

.first-section-left-div {
    width: 250px;
    height: fit-content;
    padding: 10px;
    /* background-color: red; */
}
/* ............................................................... */


.first-section-mid-div .first-section-mid-lorem-div{
    width: inherit;
    height: fit-content;
    /* background-color: #2bace2; */
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.first-section-left-div .first-section-lorem-left p {
    padding: 7px;
    font-size: 18px;
    color: black;
    text-transform: capitalize;
}


/* .................................. */
@media(max-width:760px){
    .first-section-left-div {
        width: 160px;
        
    }
    .first-section-left-div .first-section-lorem-left p {
        font-size:13px;
    }
    .first-section-mid-div {
        width: 160px;
    
        
    }
    .first-section-top-div .first-section-mid-div .first-section-mid-lorem-div p {
        font-size:13px;
        
    }
   
}
/* ............................................ */

.first-section-top-div .first-section-mid-div .first-section-mid-lorem-div {
    width: inherit;
    height: fit-content;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.first-section-top-div .first-section-mid-div .first-section-mid-lorem-div p {
    padding: 7px;
    font-size: 18px;
    text-transform: capitalize;
}

.first-section-top-div .first-section-right-div {

    width: fit-content;
    height:100%;
    margin-top: -120px;
}

@media(max-width:1024px){
    .first-section-top-div .first-section-right-div {
        margin-top:0;
    }
}


.first-section-top-div .first-section-right-div .first-section-right-div-data {
    width: fit-content;
    height: fit-content;
    margin: 40px;
    border-radius: 20px;
    border: 2px solid black;
 
}

@media only screen and (min-width: 720px) and (max-width: 1024px) {
    /* Your styles here */

 .first-section-top-div  .first-section-right-div .car-div{
  
    height: fit-content;

    margin-left: 20px;

   }
}

.first-section-top-div  .first-section-right-div .car-div{
   
    height: fit-content;
    width: fit-content;
   }

@media(max-width:760px){
    .first-section-top-div .first-section-right-div .first-section-right-div-data {
        width: 100%;
       margin-left:-5px;
       padding-left:5px;
       padding-top: 2px;
    }
}

.first-section-top-div .first-section-right-div .first-section-right-div-data p {
    margin-left: 10px;
}


.first-section-top-div .first-section-right-div .first-section-right-div-data .p1 {
    color: #2bace2;
    font-size: 30px;
    font-weight: 900;
    margin-top: -0px;

}

.first-section-top-div .first-section-right-div .first-section-right-div-data .p2 {
    color: #2a3c90;
    font-size: 13px;
    margin-left: 15px;
    margin-top: -38px;
    font-weight: 900;
}

.first-section-top-div .first-section-right-div .first-section-right-div-data .p3 {
    font-size: 25px;
    font-weight: 900;
    margin-top: -10px;
    margin-right: 10px;
}

@media(max-width:560px) {
    .first-section-top-div .first-section-right-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .first-section-top-div .first-section-right-div .first-section-right-div-data .p3 {
        font-size: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }

}

@media(min-width:539px) and (max-width:550px) {
    .first-section-top-div .first-section-right-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
    }
}
@media(min-width:760px) and (max-width:1281px) {
    .first-section-top-div .first-section-right-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
    }

    .first-section-top-div .first-section-right-div .first-section-right-div-data .p3 {
        padding-right: 7px;
        padding-left: 5px;
    }
}



.first-section-top-div .first-section-right-div-car img {
    width: 700px;
    height: 350px;
  
  
  
   

}


  
  
  
  


@media(max-width:560px){
    .first-section-top-div .first-section-right-div-car img {
        width: 300px;
        height: 120px;
     
    
    }
    .first-section-top-div .first-section-right-div {
        width: 80%;
    }
}

.first-section-top-div .contain-both{
    height: inherit;
    width: fit-content;
    display: flex;



}
@media(max-width:760px){
    .first-section-top-div .contain-both{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .first-section-top-div .first-section-mid-div{
        width: 160px;

    }
    /* .first-section-top-div .first-section-mid-div .first-section-mid-lorem-div{
        width: 160px;
        height: 30px;
    } */

    .first-section-top-div .first-section-mid-div .first-section-mid-lorem-div p{
        font-size: 12px;
    }
}
.first-section-top-div .first-section-mid-div .right{
    background-color: white;
    height: 40px;
}


.first-section-top-div .first-section-mid-div .odd{
    background-color: #2bace2;
  

}
.first-section-top-div .eve {
    background-color: rgb(68, 216, 222);
 
}


.first-section-top-div .eve {
    background-color: rgb(68, 216, 222);
}
.first-section-top-div .first-section-mid-div .right p{
    font-size: 14.5px;
    padding-top:13px;

}
.first-section-top-div .first-section-mid-div .left{
    height: 40px;
}
.first-section-top-div .first-section-mid-div .left p{
    font-size: 14.5px;
    padding-top: 13px; 
}
.first-section-mid-div {
    width: 270px;
    height: fit-content;
    padding: 10px;
}
@media(max-width:720px)
{
    .first-section-top-div .first-section-mid-div .left p{
        font-size: 12px;
    }
    .first-section-top-div .first-section-mid-div .right p{
        font-size: 12px;
    }
}

._6zaz .vehicle-details p {
    font-size: 16px;
    font-weight: 600;
}

._6zaz .car-image {
    width: 610px;
    height: 380px;
    background-color: rgba(255, 255, 255, 0.364);
    display: flex;
    justify-content: center;
    align-items: center;
}

._6zaz .car-image img {
    transform: translateX(-50px);
}
