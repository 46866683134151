._3dmy {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

}

._3dmy .summary-sub {
    display: flex;
    /* text-align: center; */
    height: fit-content;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* gap: 20px; */
    /* background-color: black; */

}
._3dmy .summary-sub .main-1 .main-5 .line-bottom-text{
    /* background-color: black; */
    margin-top: -51px;
}



._3dmy .summary-sub h2,
h5,
h1 {
    text-align: center;

}

._3dmy .summary-sub h2 {
    color: #2bace2;
    font-size: 70px;
}

._3dmy .summary-sub h1 {
    color: #2a3c90;
    font-weight: 900;
    margin-top: -20px;
}

._3dmy .summary-sub h5 {
    color: #2a3c90;
    font-size: 30px;
    margin-top: -70px;
}


._3dmy .summary-sub .text-container {
    width: 100%;
    height: fit-content;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    /* background-color: red; */
}
._3dmy .summary-sub .text-container .logo{
    height:70px;
    width:400px;
}


._3dmy .summary-sub .line-container .first {
    display: flex;
    width: 100%;
    height: inherit;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: orange; */
}


._3dmy .summary-sub .line-main-top-container .line-top-text {
    position: absolute;
    z-index: 1;
    background-color: #2a3c90;
    width: 260px;
    height: 50px;
    margin-top: -404px;
    border-radius: 50px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}

._3dmy .summary-sub .line-main-top-container .line-top-text::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    left: 0;
    border-radius: 50%;
}

._3dmy .summary-sub .line-main-top-container .line-top-text::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    right: 0;
    border-radius: 50%;
}

._3dmy .summary-sub .line-main-top-container .line-top-text p {
    color: white;
    font-size: 25px;
}

._3dmy .summary-sub .line-container .first .one-div {
    height: 200px;
    width: 170px;
    /* background-color: yellowgreen; */
    display: flex;

    flex-direction: column;
    gap: 10px;
}

._3dmy .summary-sub .line-container .first .one-div .data {
    width: 100%;
    height: 40px;
    /* justify-content: center;
    align-items: center; */
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

}

._3dmy .summary-sub .line-container .first .one-div .change-color {
    background-color: #2bace2;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

._3dmy .summary-sub .line-container .second {
    width: 100%;
    height: 107px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
}

._3dmy .summary-sub .line-main-top-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    gap: 10px;
    /* background-color: pink; */

}


._3dmy .summary-sub .line-main-top-container::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 397px;
    margin-top: 2px;
    background-color: white;
    /* background-color: red; */
    /* background-color: red; */
    /* left line */
    left: 0;
    z-index: 1;
    margin-left: 150px;
}



._3dmy .summary-sub .line-main-top-container-circle::after {
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    background-color: #2a3c90;
    left: 0;
    z-index: 10;
    border-radius: 50%;
    margin-left: 158px;
    margin-top: -403px;
}

._3dmy .summary-sub .line-container {
    width: 70%;
    height: fit-content;
    display: flex;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    flex-wrap: wrap;
    padding: 40px;
    border: 6px solid #2a3c90;
    /* border-top:5px solid #2a3c90;
    border-right: 5px solid #2a3c90;
    border-bottom: 5px solid #2a3c90; */
    gap: 10px;
    border-radius: 20px;
    /* background-color: black; */
}

._3dmy .summary-sub .second-line-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 300px;
    /* background-color: aqua; */
    flex-wrap: wrap;
}

._3dmy .summary-sub .second-line-main-container .second-line-container {
    width: 75%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* border: 6px solid #2a3c90; */
    /* border-bottom: 6px solid #2a3c90; */
    border-left: 6px solid #2a3c90;

    border-radius: 20px;
    margin-top: -15px;
}

._3dmy .summary-sub .second-line-main-container .line-bottom-text {
    position: absolute;
    z-index: 1;
    background-color: #2a3c90;
    width: 260px;
    height: 50px;
    display: flex;
    margin-top: -27px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50px;
    margin-left: 30px;

}

._3dmy .summary-sub .second-line-main-container .line-bottom-text::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    left: 0;
    border-radius: 50%;

}

._3dmy .summary-sub .second-line-main-container .line-bottom-text::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    right: 0;
    border-radius: 50%;
}

._3dmy .summary-sub .second-line-main-container .line-bottom-text p {
    font-size: 25px;
    color: white;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container {
    width: 100%;
    height: fit-content;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    flex-wrap: wrap;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first {
    width: 100%;
    height: fit-content;
    /* background-color: greenyellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: flex-end;


}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first .one {
    height: 80px;
    width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* background-color: black; */
    flex-wrap: wrap;
    gap: 10px;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first .one .text {
    height: 30px;
    width: 100%;
    /* background-color: gainsboro; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first .one .bluecolor {
    background-color: #2bace2;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first .one .whitecolor {
    background-color: white;
}

._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-second {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #2a3c90; */
}

._3dmy .summary-sub .check-line {
    /* background-color: red; */
    /* margin-top: -20px; */
    margin-left: -5px;
    margin-top: -15px;
}

._3dmy .summary-sub .line-container .hide-data {
    position: absolute;
    background-color: white;
    width: 10px;
    height: 15px;
    z-index: 1;
    margin-left: -44px;
    margin-top: -47px;
}

/* ._3dmy .summary-sub .change-data{
    border-right: 6px solid #2a3c90;
} */

._3dmy .summary-sub .second-line-main-container .change-data {
    border-right: 6px solid #2a3c90;
    border-bottom: 6px solid #2a3c90;
    border-top: 6px solid #2a3c90;
    border-left: none;
    /* background-color: red; */
    margin-top: -35px;
    width: 76%;
    margin-left: 0px;
}

._3dmy .summary-sub .second-line-main-container .pin-data {
    position: absolute;
    background-color: white;
    width: 40px;
    height: 20px;
    left: 0;
    margin-top: -30px;
    margin-left: 150px;
}


._3dmy .summary-sub .second-line-main-container .change-data .second-line-both-container .both-first {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;

}

._3dmy .summary-sub .getval .line-bottom-text {
    margin-top: -58px;
}

._3dmy .summary-sub .second-line-main-container .main {
    /* background-color: red; */
    margin-top: -45px;
    /* border-bottom: 6px solid #2a3c90; */


}

._3dmy .summary-sub .second-line-main-container .main .main-div {
    position: absolute;
    background-color: white;
    width: 25px;
    height: 30px;
    left: 0;
    margin-left: 162px;
    margin-top: -298px;
}

._3dmy .summary-sub .second-line-main-container .anish3 {
    margin-top: -60px;

}

._3dmy .summary-sub .second-line-main-container .up {

    margin-top: -82px;
}

._3dmy .summary-sub .second-line-main-container .anish3 .hide-seven {
    position: absolute;
    background-color: white;
    width: 40px;
    height: 20px;
    left: 0;
    margin-left: 155px;
    margin-top: -280px;

}

._3dmy .summary-sub .anish4 .line-bottom-text {
    margin-top: -77px;
}

._3dmy .summary-sub .anish4 .border-remove-last-div {
    border-bottom: 6px solid #2a3c90;
}

._3dmy .summary-sub .anish4 .border-remove-last-div .none-line {
    position: absolute;
    background-color: white;
    width: 30px;
    height: 20px;
    left: 0;
    margin-left: 160px;
    margin-top: -308px;
}

._3dmy .summary-sub .anish4 .border-remove-last-div .none-line::after {
    content: "";
    position: absolute;
    background-color: #2a3c90;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 1035px;
    margin-top: 304px;


}


/* media query.............................. */
@media(max-width:768px) {
    ._3dmy .summary-sub h2 {
        color: #2bace2;
        font-size: 30px;
        line-height: 0;
        margin-bottom: 20px;

    }
   
    ._3dmy .summary-sub .text-container .logo{
        height:70px;
        width:250px;
    }

    ._3dmy .summary-sub .gapset {

        margin-top: 40px;
        /* background-color: red; */
    }

    ._3dmy .summary-sub .getval .line-bottom-text {
        margin-top: -70px;
        /* background-color: black; */
    }


    ._3dmy .summary-sub .anish4 .border-remove-last-div .none-line {
        display: none;
    }

    ._3dmy .summary-sub .anish4 {
        margin-top: 130px;
        /* background-color: red; */

    }

    ._3dmy .summary-sub .anish4 .line-bottom-text {
        margin-top: -20px;
    }

    ._3dmy .summary-sub .anish4 .border-remove-last-div {
        border: none;
    }


    ._3dmy .summary-sub h1 {
        font-size: 10px;
        margin-bottom: 20px;
    }

    ._3dmy .summary-sub h5 {
        color: #2a3c90;
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    ._3dmy .summary-sub .line-container {
        border: none;
    }

    ._3dmy .summary-sub .line-main-top-container::before {
        display: none;
    }

    ._3dmy .summary-sub .line-main-top-container-circle::after {
        display: none;
    }

    ._3dmy .summary-sub .line-container .first {
        width: 100%;
    }

    ._3dmy .summary-sub .line-container {
        width: 100%;

    }

    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        margin-top: -720px;

        /* regitration details */
    }
    ._3dmy .summary-sub .line-main-top-container {
        margin-top: 40px;
        /* background-color: black; */
        width: 100%;
    }
    ._3dmy .summary-sub .line-main-top-container .line-container .image-div{
       /* background-color:red; */
       height: fit-content;
       order: 4;

    }

    ._3dmy .summary-sub .second-line-main-container .change-data .second-line-both-container .both-first .two {
      
        order: 3;

    }

    ._3dmy .summary-sub .line-main-top-container p {
        font-size: 10px;
        padding: 20px;
    }

    /* second line container start....................................... */
    ._3dmy .summary-sub .second-line-main-container .second-line-container {
        width: 100%;
        border: none;
    }

    ._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first .one {
        width: 170px;
    }

    ._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-first {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ._3dmy .summary-sub .second-line-main-container .second-line-container .second-line-both-container .both-second p {
        padding: 10px;

    }

    ._3dmy .summary-sub .line-container .first .image-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ._3dmy .summary-sub .line-container .second {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ._3dmy .summary-sub .line-container .hide-data {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .pin-data {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .main .main-div {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .main {
        margin-top: 0;
    }

    ._3dmy .summary-sub .second-line-main-container .pin-data {
        margin-top: 0;
    }
    ._3dmy .summary-sub .anish2 {
        /* background-color: red; */
        margin-top: 50px;
    }

    ._3dmy .summary-sub .anish2 .line-bottom-text {
        /* background-color: red; */
        margin-top: -55px;
    }

    ._3dmy .summary-sub .anish2 .two {
        margin-top: 30px;
    }

    ._3dmy .summary-sub .second-line-main-container .anish3 {
        margin-top: 0px;

    }

    ._3dmy .summary-sub .second-line-main-container .up {

        margin-top: -30px;
    }

    ._3dmy .summary-sub .second-line-main-container .anish3 .hide-seven {
        display: none;
    }

    ._3dmy .summary-sub .anish4 .border-remove-last-div .none-line::after {
        display: none;
    }

}






/* @media(min-width:768px) and (max-width:1025px) {
    ._3dmy .summary-sub h2 {
        color: #2bace2;
        font-size: 40px;
        line-height: 0;
        margin-bottom: 20px;

    }
    ._3dmy .summary-sub .second-line-main-container .second-line-container {
       
        border: none;
    }
    ._3dmy .summary-sub .line-container .hide-data {
        display: none;
    }

  

    ._3dmy .summary-sub .second-line-main-container .pin-data {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .main .main-div {
        display: none;
    }

    ._3dmy .summary-sub .anish4 .border-remove-last-div .none-line::after {
        display: none;
    }

    ._3dmy .summary-sub .anish4 .border-remove-last-div .none-line {
        display: none;
    }

    ._3dmy .summary-sub .anish4 .line-bottom-text {
        margin-top: -77px;
    }

    ._3dmy .summary-sub .anish4 .border-remove-last-div {
        border: none;
    }

    ._3dmy .summary-sub h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    ._3dmy .summary-sub h5 {
        color: #2a3c90;
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    ._3dmy .summary-sub .line-container {
        border: none;
    }

    ._3dmy .summary-sub .line-main-top-container::before {
        display: none;
    }

    ._3dmy .summary-sub .line-main-top-container-circle::after {
        display: none;
    }

    ._3dmy .summary-sub .line-container .first {
        width: 100%;
    }

    ._3dmy .summary-sub .line-container {
        width: 100%;

    }

    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        margin-top: -640px;

    }

    ._3dmy .summary-sub .line-main-top-container p {
        font-size: 15px;
        padding: 20px;


    }

    ._3dmy .summary-sub .line-container .hide-data {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .change-data .second-line-both-container .both-first .two {
     
        order: 3;
    }

    ._3dmy .summary-sub .second-line-main-container .pin-data {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .main .main-div {
        display: none;
    }

    ._3dmy .summary-sub .second-line-main-container .main {
        margin-top: 0;
    }

    ._3dmy .summary-sub .anish2 .line-bottom-text {
    
        margin-top: -55px;
    }

    ._3dmy .summary-sub .anish2 .two {
        margin-top: 30px;
    }

    ._3dmy .summary-sub .second-line-main-container .anish3 .hide-seven {
        display: none;
    }
    ._3dmy .summary-sub .anish4 .border-remove-last-div .none-line::after {
        display: none;
    }


} */











@media(min-width:768px) and (max-width:780px) {
    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        /* background-color: red; */
        margin-top: -32rem;
    }
}



@media(min-width:781px) and (max-width:900px) {

    /* registration details div start...................... */
    ._3dmy .summary-sub .line-main-top-container {
        /* background-color: red; */
        margin-top: 70px;
        width: 100%;
    }

    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        /* background-color: yellow; */
        margin-top: -42rem;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container {
        /* background-color: pink; */
        width: 100%;
        display: flex;
        border: none;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container .second p {
        padding: 20px;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container .image-div {
        order: 4;
    }

    ._3dmy .summary-sub .line-main-top-container::before {
        display: none;
    }

    ._3dmy .summary-sub .line-main-top-container-circle::after {
        display: none;
    }


    /* ownership details div start........................... */
    ._3dmy .summary-sub .main-1 {

        width: 100%;
        margin-top: 60px;
    }

    ._3dmy .summary-sub .main-1 .second-line-container {
        border: none;
        width: 100%;
    }

    ._3dmy .summary-sub .main-1 .line-bottom-text {

        margin-top: -2rem;
    }

    /* vehical summay start........................... */
    ._3dmy .summary-sub .main-2 {

        width: 100%;
    }

    ._3dmy .summary-sub .main-2 .line-top-text {

        margin-top: -37rem;
    }

    ._3dmy .summary-sub .main-2 .line-container .second {

        width: 100%;
        margin-top: -80px;

    }

    /* blacklist div start........................... */
    ._3dmy .summary-sub .main-3 {
        /* background-color: green; */
        width: 100%;
        margin-top: 50px;
    }

    ._3dmy .summary-sub .main-3 .both-second p {
        padding: 20px;
    }



    /* puc div start................................ */

    ._3dmy .summary-sub .main-5 .line-bottom-text {
        margin-top: -80px;
    }

    ._3dmy .summary-sub .main-5 .both-second p {
        padding: 20px;

    }

    /* insurance details........................ */
    ._3dmy .summary-sub .main-6 {
        width: 100%;
        margin-top: 50px;
    }

    ._3dmy .summary-sub .main-6 .line-bottom-text {

        margin-top: -100px;
    }

    ._3dmy .summary-sub .main-6 .both-second p {
        padding: 20px;

    }

    /* challan details...................... */
    ._3dmy .summary-sub .main-7 {

        margin-top: 20px;
    }

    ._3dmy .summary-sub .main-7 .line-bottom-text {
        margin-top: -70px;
    }

    ._3dmy .summary-sub .main-7 .both-second p {
        padding: 20px;
    }

}






@media(min-width:782px) and (max-width:1025px) {
    
    /* registration details div start...................... */
    ._3dmy .summary-sub .line-main-top-container {
        /* background-color: red; */
        margin-top: 70px;
        width: 100%;
    }

    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        /* background-color: yellow; */
        margin-top: -28rem;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container {
        /* background-color: pink; */
        width: 100%;
        display: flex;
        border: none;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container .second p {
        padding: 20px;
    }

    ._3dmy .summary-sub .line-main-top-container .line-container .image-div {
        order: 4;
    }

    ._3dmy .summary-sub .line-main-top-container::before {
        display: none;
    }

    ._3dmy .summary-sub .line-main-top-container-circle::after {
        display: none;
    }


    /* ownership details div start........................... */
    ._3dmy .summary-sub .main-1 {
        /* background-color: red; */
        width: 100%;
        margin-top: 30px;
    }

    ._3dmy .summary-sub .main-1 .second-line-container {
        border: none;
        width: 100%;
    }

    ._3dmy .summary-sub .main-1 .line-bottom-text {

        margin-top: -1rem;
    }
    ._3dmy .summary-sub .main-1 .both-second{
        
        margin-top:30px;
    }

    /* vehical summay start........................... */
    ._3dmy .summary-sub .main-2 {

        width: 100%;
        /* background-color: red; */
    }
 
    ._3dmy .summary-sub .main-2 .second p{
    margin-top: 140px;    
    }

    ._3dmy .summary-sub .main-2 .line-top-text {

        margin-top: -23rem;
    }

    ._3dmy .summary-sub .main-2 .line-container .second {

        width: 100%;
        margin-top: -80px;

    }
    /* finance div start.................................. */


 ._3dmy .summary-sub .main-4 .line-bottom-text{
    margin-top: -40px;
 }


    /* blacklist div start........................... */
    ._3dmy .summary-sub .main-3 {
        /* background-color: green; */
        width: 100%;
        margin-top: 50px;
    }

    ._3dmy .summary-sub .main-3 .both-second p {
        padding: 20px;
    }



    /* puc div start................................ */

    ._3dmy .summary-sub .main-5 .line-bottom-text {
        margin-top: -80px;
    }

    ._3dmy .summary-sub .main-5 .both-second p {
        padding: 20px;

    }

    /* insurance details........................ */
    ._3dmy .summary-sub .main-6 {
        width: 100%;
        margin-top: 50px;
    }

    ._3dmy .summary-sub .main-6 .line-bottom-text {

        margin-top: -100px;
    }

    ._3dmy .summary-sub .main-6 .both-second p {
        padding: 20px;

    }

    /* challan details...................... */
    ._3dmy .summary-sub .main-7 {

        margin-top: 20px;
    }

    ._3dmy .summary-sub .main-7 .line-bottom-text {
        margin-top: -70px;
    }

    ._3dmy .summary-sub .main-7 .both-second p {
        padding: 20px;
    }
}




@media(min-width:819px) and (max-width:821px)
{
    /* registration div...................................... */
    ._3dmy .summary-sub .line-main-top-container .line-top-text {
        /* background-color: yellow; */
        margin-top: -41rem;
    }
    /* puc div ....................................... */
    ._3dmy .summary-sub .main-1 .main-5 .line-bottom-text{
       
        margin-top: -82px;
    }

}

._3dmy .summary-sub .main-7 .third-four-container{
    /* background-color: black; */
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    
}
._3dmy .summary-sub .main-7 .third-four-container .first{
    /* background-color: greenyellow; */
    width: 230px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

 

}
._3dmy .summary-sub .main-7 .third-four-container .first .one{
    width: 100%;
    height: 30px;
    /* background-color: gray; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

}

@media(max-width:760px)
{
    ._3dmy .summary-sub .main-7 .third-four-container .first{
       width: 170px;
        /* background-color: red; */
    }
}

._3dmy .challan-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

._3dmy .challan-details .header,
._3dmy .challan-details .details > div {
    display: flex;
    gap: 20px;
}

._3dmy .challan-details .header span {
    width: 200px;
    background-color: rgb(43, 172, 226);
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

._3dmy .challan-details .details span {
    width: 200px;
    color: rgb(74, 74, 74);
    background-color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}



